<template>
  <!-- 应用服务 -->
  <div>
    <pc-header :active1="4"></pc-header>
    <div class="main">
      <div class="NeiBanner">
        <img
          style="width: 100%; height: 900px"
          :src="productBannerList.img_url"
          alt=""
        />
      </div>
      <div class="ServiceMain">
        <div
          class="cmain"
          v-for="(item, index) in cooperationList"
          :key="index"
        >
          <div class="SMKuaiCon">
            <div class="SMKuai">
              <em>{{ item.title }} / </em> SERVICE DETAIL
            </div>
          </div>
          <div class="SMTxt">
            <span v-html="item.content"> </span>
          </div>
          <div
            class="PDThreeImg"
            style="display: flex; justify-content: space-between"
          >
            <img
              v-if="item.banner1"
              :src="item.banner1"
              alt=""
              style="width: 545px; height: 382px"
            />
            <img
              v-if="item.banner2"
              :src="item.banner2"
              alt=""
              style="width: 545px; height: 382px"
            />
            <img
              v-if="item.banner3"
              :src="item.banner3"
              alt=""
              style="width: 545px; height: 382px"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import pcHeader from "@/components/pc-header";
export default {
  components: {
    pcHeader,
  },
  data() {
    return {
      cooperationList: [],
      productBannerList: "",
    };
  },
  methods: {
    getCooperationList() {
      this.$fetch({
        url: "/api/index/cooperationList",
      }).then((res) => {
        this.cooperationList = res.data.data;
      });
    },

    getProductBanner() {
      this.$fetch({
        url: "/api/index/productBanner",
        params: { type: 3 },
      }).then((res) => {
        this.productBannerList = res.data.data;
      });
    },
  },
  created() {
    this.getCooperationList();
    this.getProductBanner();
  },
};
</script>
<style lang="less" scoped>
.PDThreeImg {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
</style>